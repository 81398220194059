import { useCallback } from "react";
import PropTypes from "prop-types";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, {
  Navigation,
  Autoplay,
  EffectFade,
  Pagination,
} from "swiper";
import SlideItem from "./SlideItem";

import "swiper/swiper-bundle.css";
import { SliderWrapper } from "./styles";
import { EVENT_TYPES } from "../../constants/siteData";

// install Swiper modules
SwiperCore.use([Navigation, Autoplay, EffectFade, Pagination]);

const EventsSwipeBlock = ({ items = [] }) => {
  const slidesPerPage = items.length > 1 ? 1.5 : 1;
  const getEventData = useCallback((i) => {
    const { name, flyerMedia, ticketsConfigs, startAt, location, shortUrl } = i;
    const eventImage = flyerMedia?.imgUrl || "/assets/images/event_default.png";
    const locationAddress =
      location?.type === EVENT_TYPES.OFFLINE
        ? `${location?.venue?.city} ${location?.venue?.state}, ${location?.venue?.zipCode}`
        : "Virtual Event";

    return {
      name,
      ticketsConfigs,
      eventImage,
      startAt,
      location: locationAddress,
      locationType: location?.type,
      shortUrl,
    };
  }, []);

  return (
    <SliderWrapper>
      {items.length > 0 && (
        <Swiper
          id="eventsSlider"
          initialSlide={0}
          spaceBetween={5}
          slidesPerView={slidesPerPage}
          // onSwiper={(swiper) => console.log(swiper)}
        >
          {items.map((i) => (
            <SwiperSlide key={i.id}>
              <SlideItem item={getEventData(i)} />
            </SwiperSlide>
          ))}
        </Swiper>
      )}
    </SliderWrapper>
  );
};

EventsSwipeBlock.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      description: PropTypes.string,
      imageUrl: PropTypes.string,
      date: PropTypes.string,
      location: PropTypes.string,
      linkUrl: PropTypes.string,
      linkTitle: PropTypes.string,
    })
  ).isRequired,
};

export default EventsSwipeBlock;
