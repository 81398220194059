import Proptypes from "prop-types";
import Link from "next/link";
import Image from "next/image";
import {
  Wrapper,
  IconWrapper,
  ContentWrapper,
  ActionWrapper,
  LinkTitle,
  LinkSubtitle,
  Button,
} from "./styles";
import { httpsUrl } from "../../helpers/data_helpers";

const LinkItem = ({
  isPrime,
  title,
  subTitle,
  btnLabel,
  url,
  icon,
  onClick,
}) => {
  const imageHttps = httpsUrl(icon);

  return (
    <Wrapper prime={isPrime} onClick={onClick}>
      {icon && (
        <IconWrapper imageUrl={imageHttps}>
          <Image width="40" height="40" src={imageHttps} alt="icon" />
        </IconWrapper>
      )}
      <ContentWrapper>
        <LinkTitle>{title}</LinkTitle>
        <LinkSubtitle>{subTitle}</LinkSubtitle>
      </ContentWrapper>
      <ActionWrapper>
        {url && url !== "" && (
          <Link href={url}>
            <Button prime={isPrime}>{btnLabel}</Button>
          </Link>
        )}
      </ActionWrapper>
    </Wrapper>
  );
};

LinkItem.defaultProps = {
  isPrime: false,
  title: "",
  subTitle: "",
  btnLabel: "",
  url: "",
  icon: "",
  onClick: () => true,
};

LinkItem.propTypes = {
  isPrime: Proptypes.bool,
  title: Proptypes.string,
  subTitle: Proptypes.string,
  btnLabel: Proptypes.string,
  url: Proptypes.string,
  icon: Proptypes.string,
  onClick: Proptypes.func,
};

export default LinkItem;
