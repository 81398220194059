import PropTypes from "prop-types";
import { SnapFlowProvider } from "./context";
import SnapFlowPageContent from "./content";

const SnapFlowPage = ({ data }) => (
  <SnapFlowProvider data={data}>
    <SnapFlowPageContent />
  </SnapFlowProvider>
);

SnapFlowPage.propTypes = {
  data: PropTypes.shape().isRequired,
};

export default SnapFlowPage;
