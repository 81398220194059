import { useContext } from "react";
import {
  ContentWrapper,
  PageSectionTitle,
  PageSectionWrapper,
} from "../../styles";
import LinkItem from "../../../components/LinkItem";
import { LinkWrapper } from "./styles";
import { SnapFlowContext } from "../context";
import EventsSwipeBlock from "../../../components/EventsSwipeBlock";
import ShopSwipeBlock from "../../../components/ShopSwipeBlock";

const SnapFlowPageContent = () => {
  const {
    data,
    eventItems = [],
    productItems = [],
    displayEventsOnHomePage,
    displayProductsOnHomePage,
  } = useContext(SnapFlowContext);

  if (!data || !data.account) {
    return <> No Content </>;
  }

  const {
    account: { smartLinks, snapflowShortUrl },
  } = data;

  const firstItem = smartLinks.find((i) => i.featured);
  const hasTitles =
    displayEventsOnHomePage || displayProductsOnHomePage || false; // Will be added more sections here

  return (
    <>
      {firstItem && (
        <LinkItem
          isPrime
          title={firstItem.title}
          subTitle={firstItem.subtitle}
          btnLabel={firstItem.btnLabel.replace("_", " ")}
          icon={firstItem?.iconMedia?.uri}
          url={firstItem.url}
        />
      )}

      {displayProductsOnHomePage && productItems.length > 0 && (
        <PageSectionWrapper>
          <PageSectionTitle>Shop</PageSectionTitle>
          <ShopSwipeBlock items={productItems} accountUrl={snapflowShortUrl} />
        </PageSectionWrapper>
      )}

      {displayEventsOnHomePage && eventItems.length > 0 && (
        <PageSectionWrapper>
          <PageSectionTitle>Dates</PageSectionTitle>
          <EventsSwipeBlock items={eventItems} />
        </PageSectionWrapper>
      )}

      <ContentWrapper nopadding>
        <PageSectionWrapper>
          {hasTitles && <PageSectionTitle>Links</PageSectionTitle>}
          <LinkWrapper>
            {smartLinks
              .slice()
              .sort((a, b) => a.position - b.position)
              .map((link) => {
                const { position, featured, title, subtitle, btnLabel, url } =
                  link;
                return (
                  !featured && (
                    <LinkItem
                      link={link}
                      isPrime={false}
                      key={position}
                      title={title}
                      subTitle={subtitle}
                      btnLabel={btnLabel.replace("_", " ")}
                      url={url}
                      icon={link?.iconMedia?.uri}
                    />
                  )
                );
              })}
          </LinkWrapper>
        </PageSectionWrapper>
      </ContentWrapper>
    </>
  );
};

SnapFlowPageContent.propTypes = {};

export default SnapFlowPageContent;
