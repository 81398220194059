import styled from "styled-components";

export const Wrapper = styled.div``;
export const LinkWrapper = styled.div`
  align-self: stretch;
  display: flex;
  flex-flow: column;
  flex: 1 0 auto;
  background: var(--card-bg-color);
  color: var(--text-color);
`;
