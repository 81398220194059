import { useCallback } from "react";
import PropTypes from "prop-types";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, {
  Navigation,
  Autoplay,
  EffectFade,
  Pagination,
} from "swiper";
import { sortBy } from "lodash";
import SlideItem from "./SlideItem";
import "swiper/swiper-bundle.css";
import { SliderWrapper } from "./styles";
import {
  checkHttpsUrl,
  httpsUrl,
  stripTrailingSlash,
} from "../../helpers/data_helpers";

SwiperCore.use([Navigation, Autoplay, EffectFade, Pagination]);

const ShopSwipeBlock = ({ accountUrl, items = [] }) => {
  const getEventData = useCallback((i) => {
    const { name, startAt, shortId, shortUrl, priceCents, media = [] } = i;
    const sortedMedia = sortBy(media, "position");
    return {
      name,
      startAt,
      shortId,
      shortUrl,
      priceCents,
      imageUrl: sortedMedia[0]?.url,
    };
  }, []);

  const getProductUrl = useCallback(
    (productId) => {
      const accUrl = httpsUrl(checkHttpsUrl(accountUrl));
      return `${stripTrailingSlash(accUrl)}/p/${productId}`;
    },
    [accountUrl]
  );

  return (
    <SliderWrapper>
      {items.length > 0 && (
        <Swiper
          id="eventsSlider"
          initialSlide={0}
          spaceBetween={5}
          slidesPerView={2.5}
          // onSwiper={(swiper) => console.log(swiper)}
        >
          {items.map((i) => (
            <SwiperSlide key={i.id}>
              <SlideItem item={getEventData(i)} getProductUrl={getProductUrl} />
            </SwiperSlide>
          ))}
        </Swiper>
      )}
    </SliderWrapper>
  );
};

ShopSwipeBlock.propTypes = {
  accountUrl: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      description: PropTypes.string,
      imageUrl: PropTypes.string,
      date: PropTypes.string,
      location: PropTypes.string,
      linkUrl: PropTypes.string,
      linkTitle: PropTypes.string,
    })
  ).isRequired,
};

export default ShopSwipeBlock;
