import styled, { css } from "styled-components";
import { grey } from "@material-ui/core/colors";
import Button from "@material-ui/core/Button";

export const SliderWrapper = styled.div`
  display: block;
  padding: 0 0.5rem;
`;

export const SlideWrapper = styled.div`
  display: block;
  padding: 0 0.5rem;
`;

export const SwiperContent = styled.div`
  position: relative;
  background-color: var(--background-color);
  border: 1px solid var(--border-dark-color);
  box-shadow: 0 0 0.5rem 0 rgba(0, 0, 0, 0.1);
  border-radius: 0.5rem;
  overflow: hidden;
`;

export const SwiperContentImage = styled.div`
  background-color: grey;
  box-shadow: 0 0 0.5rem 0 rgba(0, 0, 0, 0.1);
  border-radius: 0 0 0.5rem 0.5rem;
  min-height: 200px;
  position: relative;

  ${(props) =>
    props.bgImage &&
    css`
      background-image: url(${props.bgImage});
      background-size: cover;
      background-repeat: no-repeat;
    `}
`;

export const SlideInfo = styled.div`
  display: grid;
  height: 60px;
  grid-template-columns: 60px 1fr;
  padding: 0.5rem 0.25rem;
`;

export const SlideData = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  max-height: 60px;
  background-color: var(--background-color, ${grey[50]});
  color: var(--text-color);
  border-right: 1px solid var(--border-dark-color);
`;

export const SlideInfoContent = styled.div`
  display: flex;
  flex-flow: column nowrap;
  max-height: 60px;
  background-color: var(--background-color, ${grey[50]});
  color: var(--text-color);
  padding: 0 0.75rem;
`;

export const Title = styled.p`
  font-size: 1em;
  font-weight: 600;
  line-height: 1.4;
  margin: 0;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const Text = styled.p`
  font-size: 0.7em;
  font-weight: 400;
  line-height: 1.4;
  margin: 0;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const DayWeek = styled.p`
  font-size: 0.6em;
  font-weight: 600;
  line-height: 1;
  text-transform: uppercase;
  margin: 0;
`;

export const Day = styled.p`
  font-size: 2em;
  font-weight: 600;
  line-height: 0.9;
  text-transform: uppercase;
  margin: 0;
`;

export const Month = styled.p`
  font-size: 0.7em;
  font-weight: 400;
  line-height: 1;
  text-transform: uppercase;
  margin: 0;
`;

export const SlideBtn = styled(Button)`
  &.MuiButton-root {
    &,
    &:hover {
      position: absolute;
      top: 0.5rem;
      right: 0.5rem;
      box-shadow: none;
      background-color: var(--brand-color);
      color: var(--text-light-color);
    }
  }
`;
