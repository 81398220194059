import { useCallback } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { useRouter } from "next/router";
import {
  SwiperContent,
  SlideWrapper,
  SlideInfo,
  Title,
  Text,
  SlideData,
  SlideInfoContent,
  DayWeek,
  Day,
  Month,
  SlideBtn,
  SwiperContentImage,
} from "./styles";
import { EVENT_TYPES } from "../../constants/siteData";
import { httpsUrl } from "../../helpers/data_helpers";

const SlideItem = ({ item = {} }) => {
  const {
    name,
    ticketsConfigs,
    eventImage,
    startAt,
    location,
    shortUrl,
    locationType,
  } = item;
  const thisDate = moment(startAt);
  const day = thisDate.isValid() ? thisDate.format("DD") : "XX";
  const month = thisDate.isValid() ? thisDate.format("MMM") : "XXX";
  const dw = thisDate.isValid() ? thisDate.format("ddd") : "xxx";
  const btnTitle = locationType === EVENT_TYPES.OFFLINE ? "View" : "Join";

  const router = useRouter();

  const goToUrl = useCallback(() => {
    router.push(shortUrl);
  }, [router, shortUrl]);

  const imageHttps = httpsUrl(eventImage);

  const getTicketsPrice = useCallback((tickets = []) => {
    let min;
    let max = 0;
    tickets.forEach(({ priceCents }) => {
      if (priceCents !== null) {
        min = min === undefined ? priceCents : Math.min(min, priceCents);
      }
      max = Math.max(priceCents, max);
    });

    if (max === 0) return "Free";
    return `Starts at $${(min / 100).toFixed(2)}`;
  }, []);

  return (
    <SlideWrapper onClick={shortUrl ? goToUrl : null}>
      <SwiperContent bgImage={imageHttps}>
        <SwiperContentImage bgImage={imageHttps} />
        <SlideBtn variant="contained" href={shortUrl} size="small">
          {btnTitle}
        </SlideBtn>

        <SlideInfo>
          <SlideData>
            <DayWeek>{dw}</DayWeek>
            <Day>{day}</Day>
            <Month>{month}</Month>
          </SlideData>
          <SlideInfoContent>
            <Title>{name}</Title>
            <Text>{location}</Text>
            <Text>{getTicketsPrice(ticketsConfigs)}</Text>
          </SlideInfoContent>
        </SlideInfo>
      </SwiperContent>
    </SlideWrapper>
  );
};

SlideItem.propTypes = {
  item: PropTypes.shape({
    name: PropTypes.string,
    ticketsConfigs: PropTypes.arrayOf(PropTypes.shape()),
    eventImage: PropTypes.string,
    startAt: PropTypes.string,
    location: PropTypes.string,
    shortUrl: PropTypes.string,
    locationType: PropTypes.string,
  }).isRequired,
};

export default SlideItem;
