import PropTypes from "prop-types";
import handleGraphQLErrors from "../../network/utils";
import getClient from "../../apollo-client";
import {
  GET_EVENTS_LIST,
  GET_MICROSITE_LINKS,
  GET_PRODUCTS_LIST,
} from "../../network/queries";
import MicrositeLayout from "../../layouts/MicrositeLayout";
import SnapFlowPage from "../../site-pages/SnapFlowPage";

const UserPage = ({ data }) => <SnapFlowPage data={data} />;

UserPage.Layout = MicrositeLayout;

export const getServerSideProps = async (context) => {
  const client = await getClient();
  let products;
  let events;
  let data;

  const eventPromise = new Promise((resolve) => {
    client
      .query({
        query: GET_EVENTS_LIST,
        errorPolicy: "all",
        variables: {
          username: context.params.userHandle,
        },
      })
      .then((res) => {
        // Set default value.
        // By default, data can be filled even if we have errors.
        events = res.data || { account: {} };

        if (res.errors) {
          handleGraphQLErrors(res.errors, context.params.userHandle);
        }

        resolve();
      })
      .catch(() => {
        events = {
          data: {
            account: {},
          },
        };
      });
  });

  const productPromise = new Promise((resolve) => {
    client
      .query({
        query: GET_PRODUCTS_LIST,
        errorPolicy: "all",
        variables: {
          username: context.params.userHandle,
        },
      })
      .then((res) => {
        products = res.data || { account: {} };

        if (res.errors) {
          handleGraphQLErrors(res.errors, context.params.userHandle);
        }

        resolve();
      })
      .catch(() => {
        products = {
          data: {
            account: {},
          },
        };
      });
  });

  const accountPromise = new Promise((resolve) => {
    client
      .query({
        query: GET_MICROSITE_LINKS,
        errorPolicy: "all",
        variables: {
          username: context.params.userHandle,
        },
      })
      .then((res) => {
        data = res.data || { account: {} };

        if (res.errors) {
          handleGraphQLErrors(res.errors, context.params.userHandle);
        }

        resolve();
      })
      .catch(() => {
        data = {
          data: {
            account: {},
          },
        };
      });
  });

  await accountPromise;
  const mode = data.account?.microsite?.style?.mode;
  await eventPromise;
  await productPromise;

  return {
    props: {
      data: { ...data, events, products },
      pageData: {
        hasVpn: data.account?.hasVpn || null,
        textUrl: data.account?.microsite?.heroSectionContent?.btnUrl || null,
        vpn: data.account?.microsite?.heroSectionContent?.friendlyVpn || null,
        title: data.account?.profile?.name || null,
        description: data.account?.profile?.title || null,
        isDark: mode === "DARK",
        account: data.account,
        avatarImg: data.account?.profile?.avatar?.uri || null,
        coverImg:
          data.account?.microsite?.linksPage?.heroSection?.img?.uri || null,
        coverMedia:
          data.account?.microsite?.linksPage?.heroSection?.video?.uri || null,
        defaultCoverMedia:
          data.account?.microsite?.defaultHeroSection?.video?.uri || null,
        defaultCoverImg:
          data.account?.microsite?.defaultHeroSection?.img?.uri || null,
        showUserInfo: true,
      },
    },
  };
};

UserPage.propTypes = {
  data: PropTypes.shape().isRequired,
};

export default UserPage;
