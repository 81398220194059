import { createContext, useMemo } from "react";
import PropTypes from "prop-types";

export const SnapFlowContext = createContext({});

export const SnapFlowProvider = ({ data, children }) => {
  const eventItems = useMemo(
    () => data?.events?.account?.availableEvents || [],
    [data?.events?.account?.availableEvents]
  );
  const productItems = useMemo(
    () => data?.products?.account?.availableProducts || [],
    [data?.products?.account?.availableProducts]
  );
  const displayEventsOnHomePage =
    data?.events?.account?.displayEventsOnHomePage;
  const displayProductsOnHomePage =
    data?.products?.account?.displayProductsOnHomePage;

  const contextData = useMemo(
    () => ({
      data,
      eventItems,
      productItems,
      displayEventsOnHomePage,
      displayProductsOnHomePage,
    }),
    [
      data,
      displayEventsOnHomePage,
      displayProductsOnHomePage,
      eventItems,
      productItems,
    ]
  );

  return (
    <SnapFlowContext.Provider value={contextData}>
      {children}
    </SnapFlowContext.Provider>
  );
};

SnapFlowProvider.propTypes = {
  data: PropTypes.shape().isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};
