import styled from "styled-components";
import { grey, purple } from "@material-ui/core/colors";
import Button from "@material-ui/core/Button";

export const SliderWrapper = styled.div`
  display: block;
  padding: 0 0.5rem;
`;

export const SlideWrapper = styled.div`
  display: block;
  padding: 0 0.5rem;
`;

export const SwiperContent = styled.div``;

export const SlideInfo = styled.div`
  display: grid;
  grid-template-columns: 60px 1fr;
  border-radius: 0.5rem 0.5rem 0.5rem 0;
  background-color: grey;
  overflow: hidden;
`;

export const SlideData = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  background-color: var(--brand-color, ${purple[600]});
  color: var(--text-light-color);
  padding: 0.25rem;
`;

export const SlideInfoContent = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  justify-content: space-between;
  background-color: ${grey[700]};
  color: var(--text-light-color);
  padding: 0.25rem 0.75rem;
`;

export const Title = styled.p`
  font-size: 1em;
  font-weight: 600;
  line-height: 1.4;
  margin: 0;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  color: var(--text-color);
`;

export const Text = styled.p`
  font-size: 0.7em;
  font-weight: 400;
  line-height: 1.4;
  margin: 0;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  color: var(--text-color);
`;

export const ProductVideo = styled.div`
  aspect-ratio: 9/16;
  display: flex;
  flex-flow: column;
  min-height: 230px;
  margin-bottom: 0.5rem;
  background-color: grey;
  border-radius: 0.5rem;
  position: relative;
  overflow: hidden;
`;

export const ProductImage = styled.div`
  aspect-ratio: 9/16;

  display: flex;
  flex-flow: column;
  min-height: 230px;
  margin-bottom: 0.5rem;
  background-color: grey;
  border-radius: 0.5rem;
  background-size: cover;
  background-position: top center;
  background-repeat: no-repeat;
  background-image: ${(props) =>
    `url("${props.src || "/images/product_default_cover.png"}")`};
`;

export const DayWeek = styled.p`
  color: var(--text-light-color, #ffffff);
  font-size: 1rem;
  font-weight: 600;
  line-height: 1;
  text-transform: uppercase;
  margin: 0.25rem 0;
`;

export const Day = styled.p`
  color: var(--text-light-color, #ffffff);
  font-size: 2em;
  font-weight: 600;
  line-height: 0.9;
  text-transform: uppercase;
  margin: 0;
`;

export const Month = styled.p`
  color: var(--text-light-color, #ffffff);
  font-size: 1rem;
  font-weight: 200;
  line-height: 1;
  text-transform: uppercase;
  margin: 0.25rem 0;
`;

export const SlideBtn = styled(Button)`
  &.MuiButton-root {
    &,
    &:hover {
      box-shadow: none;
      background-color: var(--brand-color);
      color: var(--text-light-color);
      padding: 0.25rem 2rem;
      border-radius: 500rem;
      font-size: 0.6rem;
      margin: 0.25rem 0;
    }
  }
`;
