import { useCallback } from "react";
import PropTypes from "prop-types";
import { useRouter } from "next/router";
import ReactPlayer from "react-player";
import {
  SwiperContent,
  SlideWrapper,
  Title,
  Text,
  ProductImage,
  ProductVideo,
} from "./styles";
import PriceCentsCalc from "../PriceCentsCalc";
import { httpsUrl, isVideoType } from "../../helpers/data_helpers";

const SlideItem = ({ item = {}, getProductUrl }) => {
  const { name, shortId, shortUrl, imageUrl, priceCents } = item;
  const router = useRouter();
  const goToUrl = useCallback(() => {
    let url = shortUrl;
    if (getProductUrl && typeof getProductUrl === "function") {
      url = getProductUrl(shortId);
    }
    router.push(url);
  }, [router, shortId, shortUrl, getProductUrl]);

  const imageHttps = httpsUrl(imageUrl);
  const isVideo = isVideoType(imageUrl);

  return (
    <SlideWrapper onClick={shortUrl ? goToUrl : null}>
      <SwiperContent>
        {isVideo && (
          <ProductVideo>
            <ReactPlayer
              url={imageUrl}
              playsinline
              autoplay
              playing
              loop
              muted
              width="100%"
              height="100%"
            />
          </ProductVideo>
        )}
        {!isVideo && <ProductImage src={imageHttps} />}
        <Title>{name}</Title>
        <Text>
          <PriceCentsCalc currency="$" priceCents={priceCents} toFixed={2} />
        </Text>
      </SwiperContent>
    </SlideWrapper>
  );
};

SlideItem.propTypes = {
  getProductUrl: PropTypes.func,
  item: PropTypes.shape({
    name: PropTypes.string,
    ticketsConfigs: PropTypes.arrayOf(PropTypes.shape()),
    eventImage: PropTypes.string,
    startAt: PropTypes.string,
    location: PropTypes.string,
    shortUrl: PropTypes.string,
    locationType: PropTypes.string,
  }).isRequired,
};

export default SlideItem;
